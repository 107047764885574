/* FILEPATH: /Users/sj/Developer/OE/xyla/web/apps/sec/src/components/Finance/BisonLanding/index.modules.css */

/* Styles for h1 */
.title {
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.204px;
  margin-bottom: 120px;
  margin-top: 0px;
}

.title span {
  color: rgba(255, 255, 255, 0.19);
}

/* Styles for p */
.text {
  color: #ffffff80;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.15px;
  margin-left: 4px;
}

.text a {
  color: #ffffff80;
  /* text-decoration: none; */
}

/* Styles for box */
.box {
  border-radius: 23px;
  padding: 32px;
  max-width: 470px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  background: rgba(8, 8, 8, 0.47);
  backdrop-filter: blur(1px);
  z-index: 1;
  margin: 20px;
  transition: all 300ms ease-in-out;
}

.box:hover {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(12, 12, 12, 0.47);
}

/* Styles for full-screen container */
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.button {
  display: flex;
  width: 100%;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: linear-gradient(91deg, #ffac2f 13.99%, #ab6b0b 87.39%);
  text-decoration: none;
  color: #000;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.3px;
  margin-top: 16px;
  cursor: pointer;
  box-shadow: none;
  border: none;
  transition: background 0.3s ease-in-out;
  background-size: 100% 100%;
}

.button:hover {
  background-size: 200% 100%;
}

.backgroundContainer {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 80px);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background {
  width: 720px;
}

@media (max-width: 800px) {
  .background {
    width: calc(100% - 20px);
    top: 10px;
    left: 10px;
  }
}

.background span {
  color: rgba(255, 255, 255, 0.2);
  font-size: 14px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
}

.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  margin: -10px 0px;
}

.loginButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 60px;
}

.loginButton {
  display: flex;
  width: 100%;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: linear-gradient(91deg, #51515181 13.99%, #46464680 87.39%);
  text-decoration: none;
  color: white;
  border: 2px solid #ffac2f;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.3px;
  margin-top: 16px;
  cursor: pointer;
  box-shadow: none;
  border: none;
  transition: background 0.3s ease-in-out;
  background-size: 100% 100%;
}

.loginButton:hover {
  background-size: 200% 100%;
}
